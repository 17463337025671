import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { FluxFlowUiModule } from '@flux/flux-flow-ui';
import { AppAuthGuard, FluxKeycloakAuthModule, PermissionService } from '@flux/flux-keycloak-auth';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {HttpBackend} from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { ViewerComponent } from './viewer/viewer.component';
import { RouterModule } from '@angular/router';
import { FluxBpmReportModule } from '@flux/flux-bpm-report';
import { ToastrModule } from 'ngx-toastr';
import { FluxCoreModule } from '@flux/flux-core';
import {PdfViewerModule} from "ng2-pdf-viewer";

export function createTranslateLoader(http: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/', suffix: '.json'},
    {prefix: '/services/fluxunicorn/api/translations/language/', suffix: '/map'}

  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    ViewerComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FluxCoreModule.forRoot(environment),
    FluxBpmReportModule.forRoot(environment),
    FluxFlowUiModule.forRoot(environment),
    FluxKeycloakAuthModule.forRoot(environment),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpBackend]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true
    }),
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: false,
      },
    }),
    PdfViewerModule,
    EffectsModule.forRoot([]),
  ],
  providers: [TranslateService,
    PermissionService,
    AppAuthGuard],
  entryComponents: [AppComponent]
})
export class AppModule implements DoBootstrap {
  constructor() {

  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    appRef.bootstrap(AppComponent);

  }
}

