import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppAuthGuard } from '@flux/flux-keycloak-auth';
import { AppComponent } from './app.component';
import { ViewerComponent } from './viewer/viewer.component';
import { FluxBpmReportComponent, ProcessReportComponent } from '@flux/flux-bpm-report';


const routes: Routes = [

  {
    path: ':terminalId',
    component: ViewerComponent,
    canActivate: [AppAuthGuard],
    children: []
  },
  {
    path: 'flow/report',
    canActivate: [AppAuthGuard],
    component: FluxBpmReportComponent,
    children: []
  },
  {
    path: 'flow/report/:processInstanceId',
    canActivate: [AppAuthGuard],
    component: ProcessReportComponent,
    children: []
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { })],
  providers: [],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
