const keycloakConfig: any = {
  url: '{ENV_KC_AUTH_SERVER}',
  realm: '{ENV_KC_REALM}',
  clientId: 'flux-workerguidance'
};

export const environment = {
  keycloak: keycloakConfig,
  production: true,
  apiBaseUrl: '/',
  viewerSocketUrl: '{ENV_VIEWER_SOCKET_URL}',
  processSocketUrl: '{ENV_PROCESS_SOCKET_URL}',
  connectorSocketUrl: '{ENV_CONNECTOR_SOCKET_URL}'
};
